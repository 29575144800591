import React, { Fragment } from "react";
import { Layout, SEO } from "src/components";
import {
  HeroTemplate,
  TextSectionTemplate
} from "src/services/prismic/templates";
import { graphql } from "gatsby";
import { PrismicPage } from "src/graphqlTypes";

type PrivacyPolicyProps = {
  data: {
    prismicPage: PrismicPage;
  };
};

const PrivacyPolicy = ({ data: { prismicPage: page } }: PrivacyPolicyProps) => {
  return (
    <Fragment>
      <SEO
        title={page.data?.title.text}
        description={page.data?.description.text}
        path="/privacy"
      />

      <Layout>
        {page?.data?.body.map((slice, idx) => {
          switch (slice.__typename) {
            case "PrismicPageBodyHero":
              return <HeroTemplate data={slice} key={idx} />;
            case "PrismicPageBodyTextSection":
              return <TextSectionTemplate data={slice} key={idx} />;
            default:
              return null;
          }
        })}
      </Layout>
    </Fragment>
  );
};

export const query = graphql`
  query {
    prismicPage(uid: { eq: "privacy" }) {
      data {
        title {
          text
        }
        description {
          text
        }
        body {
          ...PrismicHero
          ...PrismicTextSection
        }
      }
    }
  }
`;

export default PrivacyPolicy;
